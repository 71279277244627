var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "50%",
        placement: "right",
        closable: true,
        visible: _vm.isShow,
        title: "提现订单详情"
      },
      on: {
        close: function($event) {
          _vm.isShow = false
        }
      }
    },
    [
      _c(
        "a-row",
        { attrs: { justify: "space-between", type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c("a-descriptions-item", { attrs: { label: "应用APPID" } }, [
                    _vm._v(_vm._s(_vm.detailData.appId))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "提现订单号" } },
                    [
                      _c("a-tag", { attrs: { color: "purple" } }, [
                        _vm._v(_vm._s(_vm.detailData.transferId))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "商户提现单号" } },
                    [_vm._v(_vm._s(_vm.detailData.mchOrderNo))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "渠道订单号" } },
                    [_vm._v(_vm._s(_vm.detailData.channelOrderNo))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "金额" } },
                    [
                      _c("a-tag", { attrs: { color: "green" } }, [
                        _vm._v(_vm._s(_vm.detailData.amount / 100))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c("a-descriptions-item", { attrs: { label: "货币代码" } }, [
                    _vm._v(_vm._s(_vm.detailData.currency))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "收款账号" } },
                    [
                      _c("a-tag", { attrs: { color: "green" } }, [
                        _vm._v(_vm._s(_vm.detailData.accountNo))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "收款人姓名" } },
                    [_vm._v(_vm._s(_vm.detailData.accountName))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c("a-descriptions-item", { attrs: { label: "提现备注" } }, [
                    _vm._v(_vm._s(_vm.detailData.transferDesc))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "订单状态" } },
                    [
                      _c(
                        "a-tag",
                        {
                          attrs: {
                            color:
                              _vm.detailData.state === 0
                                ? "blue"
                                : _vm.detailData.state === 1
                                ? "orange"
                                : _vm.detailData.state === 2
                                ? "green"
                                : "volcano"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.detailData.state === 0
                                  ? "订单生成"
                                  : _vm.detailData.state === 1
                                  ? "提现中"
                                  : _vm.detailData.state === 2
                                  ? "提现成功"
                                  : _vm.detailData.state === 3
                                  ? "提现失败"
                                  : _vm.detailData.state === 4
                                  ? "任务关闭"
                                  : "未知"
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "提现成功时间" } },
                    [_vm._v(_vm._s(_vm.detailData.successTime))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c("a-descriptions-item", { attrs: { label: "创建时间" } }, [
                    _vm._v(_vm._s(_vm.detailData.createdAt))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c("a-descriptions-item", { attrs: { label: "更新时间" } }, [
                    _vm._v(_vm._s(_vm.detailData.updatedAt))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("a-divider"),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c("a-descriptions-item", { attrs: { label: "接口代码" } }, [
                    _vm._v(_vm._s(_vm.detailData.ifCode))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c("a-descriptions-item", { attrs: { label: "入账类型" } }, [
                    _vm._v(_vm._s(_vm.detailData.entryType))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c("a-descriptions-item", { attrs: { label: "客户端IP" } }, [
                    _vm._v(_vm._s(_vm.detailData.clientIp))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "异步通知地址" } },
                    [_vm._v(_vm._s(_vm.detailData.notifyUrl))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "a-col",
        { attrs: { sm: 12 } },
        [
          _c(
            "a-descriptions",
            [
              _c("a-descriptions-item", { attrs: { label: "渠道订单号" } }, [
                _vm._v(_vm._s(_vm.detailData.channelOrderNo))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { sm: 12 } },
        [
          _c(
            "a-descriptions",
            [
              _c("a-descriptions-item", { attrs: { label: "渠道错误码" } }, [
                _vm._v(_vm._s(_vm.detailData.errCode))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { sm: 12 } },
        [
          _c(
            "a-descriptions",
            [
              _c("a-descriptions-item", { attrs: { label: "渠道错误描述" } }, [
                _vm._v(_vm._s(_vm.detailData.errMsg))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { sm: 24 } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "渠道额外参数" } },
            [
              _c("a-input", {
                staticStyle: { height: "100px", color: "black" },
                attrs: { type: "textarea", disabled: "disabled" },
                model: {
                  value: _vm.detailData.channelExtra,
                  callback: function($$v) {
                    _vm.$set(_vm.detailData, "channelExtra", $$v)
                  },
                  expression: "detailData.channelExtra"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "a-col",
        { attrs: { sm: 24 } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "扩展参数" } },
            [
              _c("a-input", {
                staticStyle: { height: "100px", color: "black" },
                attrs: { type: "textarea", disabled: "disabled" },
                model: {
                  value: _vm.detailData.extParam,
                  callback: function($$v) {
                    _vm.$set(_vm.detailData, "extParam", $$v)
                  },
                  expression: "detailData.extParam"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }